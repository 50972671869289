/* Global */
#root {
	font-family: 'Inter', sans-serif;;
	text-align: center;
	display: grid;
}

h1 {
	font-size: 3.5rem;
	padding-bottom: 2rem;
	margin: 0;
}

h2 {
	font-size: 2.5rem;
	padding: 0 0 2rem;
	margin: 0;
}

/* Navigation */
@media screen and (max-width: 767px)  {
	#navbar {
		width: 100%;
		background-color: #4589d6;
		padding: 0 10px;
		position: sticky;
		position: -webkit-sticky; /* Safari */
		top: 0;
		z-index: 1;
	}

	.navbar-brand {
		font-size: 1.75rem;
		font-weight: 500;
		padding: 0;
	}
	
	.nav-link {
		font-size: 1.25rem;
	}

	.collapsed {
		height: 7vh;
	}

	.navbar-dark .navbar-toggler {
		border-color: rgb(255 255 255 / 0%);
	}
}

@media screen and (min-width: 768px)  {
	#navbar {
		width: 100%;
		height: 7vh;
		background-color: #4589d6;
		padding: 0 2rem;
		position: sticky;
		position: -webkit-sticky; /* Safari */
		z-index: 1;
	}

	.navbar-brand {
		font-size: 2rem;
		font-weight: 600;
	}
	
	.nav-link {
		font-size: 1.5rem;
	}

	.separator {
		border-left-style: solid;
		border-width: 2px;
		border-color: white;
		height: 45px;
		align-self: center;
		padding: 20px 0;
		margin: 0 10px;
	}
}

@media screen and (min-width: 1200px)  {
	#navbar {
		width: 100%;
		height: 7vh;
		background-color: #4589d6;
		padding: 0 2rem;
		position: sticky;
		position: -webkit-sticky; /* Safari */
		z-index: 1;
	}

	.navbar-brand {
		font-size: 2.5rem;
		font-weight: 600;
	}
	
	.nav-link {
		font-size: 1.8rem;
	}

	.separator {
		border-left-style: solid;
		border-width: 3px;
		border-color: white;
		height: 45px;
		align-self: center;
		padding: 20px 0;
		margin: 0 15px;
	}
}

.navbar-brand:hover {
	transform: scale(1.05, 1.05);
}

.navbar-dark .navbar-nav .nav-link {
	color: white;
}

.nav-link-right {
	margin-left: auto;
}

.nav-link:hover {
	transform: scale(1.15, 1.15);
}

.navbar-toggler:focus {
	box-shadow: none;
}

.navbar-toggler:hover {
	color: #333;
}

/* Footer */
@media screen and (max-width: 767px)  {
	footer {
		display: grid;
		height: 5vh;
		width: 100%;
		background-color: rgb(190, 190, 190);
		font-weight: 600;
		align-items: center;
	}

	.copyright {
		justify-self: center;
	}
}

@media screen and (min-width: 768px)  {
	footer {
		display: grid;
		height: 7vh;
		width: 100%;
		background-color: rgb(190, 190, 190);
		font-weight: 600;
		align-items: center;
	}

	.copyright {
		justify-self: center;
	}
}

/* Containers or Backgrounds*/
.container-fluid {
	padding: 0;
}

.bg-light-blue {
	background-color: #D6E6F2;
}

.bg-lighter-blue {
	background-color: #B9D7EA;
}

/* Welcome */
.rcb-bg {
 height: 90vh;
 background-color: #1f7c80;
}

.main-container {
	padding: 2rem;
}

.row {
	--bs-gutter-x: 0;
}

.quote {
	font-family: 'Kalam', cursive;
	font-weight: 500;
	font-size: 3rem;
	padding: 0 0 2rem;
}

.main-img {
	border-radius: 50%;
	max-width: 75%;
}

.fa-heart {
	color: red;
}

.fa-heart {
	padding-bottom: 35px;
}

@media screen and (max-width: 767px)  {
	.immg {
		height: 90vh;
		background: no-repeat center center;
		background-size: contain;
		background-image: url("\\assets\\pics\\Rock-Creek-Bulldog-Phone.png");
	}

	.text-btn {
		color: black;
    font-size: 1.5rem;
    padding: 5px;
    display: inline-block;
    border: solid 2px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 2px 2px 6px;
	}

	.text-btn:hover {
		color: #333;
	}

	.dams-bg-img {
		display: block;
    margin: auto;
    width: 90vmin;
    box-shadow: 0 0 15px 1px purple;
	}
}

@media screen and (min-width: 768px)  {
	.immg {
		height: 90vh;
		background: no-repeat center center;
		background-size: contain;
		background-image: url("\\assets\\pics\\Rock-Creek-Bulldog.png");
	}

	.text-btn {
		color: black;
    font-size: 2.5rem;
    padding: 5px;
    display: inline-block;
    border: solid 2px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 2px 2px 6px;
	}

	.text-btn:hover {
		color: #333;
    transform: scale(1.05, 1.05);
	}

	.dams-bg-img {
		display: block;
    margin: auto;
    width: 90vmin;
    box-shadow: 0 0 15px 1px purple;
	}
}

.last-div {
	padding-bottom: 25px;
}

.text-btn {
	text-decoration: none;
}

.btn-dams {
	background: #d2afdf;
}

.btn-dams:hover {
	background: #d6c2dd;
}

.btn-pups {
	background: #e3dd95;
}

.btn-pups:hover {
	background: #e5e0a5;
}
