@media screen and (max-width: 767px) {
  .container-dam {
    padding: 15px 0;
  }

  .row-dam {
    max-width: 90%;
    margin: auto;
    background-color: pink;
    border-radius: 20px;
    box-shadow: 0 0 5px rgb(167, 15, 40);
  }
  
  .row-dam .col {
    margin: auto;
    padding: 2.5%;
  }

  .fluid-image-dam {
    max-width: 500px;
    width: 100%;
    border-radius: 20px;
    box-shadow:  0 0 10px 8px rgb(255, 224, 229);
  }
  
  .name-dam {
    font-family: 'Dancing Script', cursive;
    font-size: 3rem;
  }
}

@media screen and (min-width: 768px) { 
  .container-dam {
    padding: 20px 0;
  }

  .row-dam {
    max-width: 90%;
    margin: 3% auto;
    background-color: pink;
    border-radius: 20px;
    box-shadow: 0 0 10px rgb(167, 15, 40);
  }
  
  .row-dam .col {
    margin: auto;
    padding: 2.5%;
  }

  .fluid-image-dam {
    max-width: 500px;
    width: 100%;
    border-radius: 20px;
    box-shadow:  0 0 10px 8px rgb(255, 224, 229);
  }
  
  .name-dam {
    font-family: 'Dancing Script', cursive;
    font-size: 6rem;
  }
}

@media screen and (min-width: 1200px) {
  .container-dam {
    padding: 25px 0;
  }

  .row-dam {
    max-width: 90%;
    margin: 3% auto;
    background-color: pink;
    border-radius: 20px;
    box-shadow: 0 0 10px rgb(167, 15, 40);
  }
  
  .row-dam .col {
    margin: auto;
    padding: 2.5%;
  }

  .fluid-image-dam {
    max-width: 500px;
    width: 100%;
    border-radius: 20px;
    box-shadow:  0 0 10px 8px rgb(255, 224, 229);
  }
  
  .name-dam {
    font-family: 'Dancing Script', cursive;
    font-size: 8rem;
  }
}