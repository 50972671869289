@media screen and (max-width: 767px) {
	.sire-and-dam-header {
		font-size: 1.75rem;
	}

	.name {
		font-size: 1.5rem;
	}
}

@media screen and (min-width: 768px) {
	.sire-and-dam-header {
		font-size: 2.25rem;
	}

	.name {
		font-size: 2rem;
	}
}

@media screen and (min-width: 1200px) {
	.sire-and-dam-header {
		font-size: 2.75rem;
	}

	.name {
		font-size: 2.5rem;
	}
}

.sire-and-dam-container {
	width: 100%;
	padding: 20px 0;
}

.sire-and-dam {
	width: 90%;
	max-width: 1200px;
	margin: auto;
	grid-template-areas: 
		"header header"
		"dam sire"
	;
	background-color: #f9ffd9;
	border-radius: 20px;
	box-shadow: 0 0 5px 2px #b2b69b;
}

.sire-and-dam-header {
	grid-area: header;
	margin: 0;
	padding: 15px 0 5px;
	font-weight: 600;
}

.sire-and-dam-flex {
	display: flex;
	width: 100%;
	justify-content: space-around;
	align-items: center;
}

.sire-and-dam-dam {
	grid-area: dam;
	flex-grow: 1;
	padding: 15px;
}

.sire-and-dam-sire {
	grid-area: sire;
	flex-grow: 1;
	padding: 15px;
}

.sire-and-dam img {
	width: 100%;
	max-width: 500px;
	max-height: 500px;
	border-radius: 20px;
	box-shadow: 0 0 5px 1px;
}

.name {
	font-weight: 500;
	padding: 10px 0 0;
	margin: 0;
}
