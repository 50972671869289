@media screen and (max-width: 767px) {
	/* PuppiesNavigation */
	.puppies-nav-container {
		width: 75%;
		margin: auto;
		padding: 10px 0 20px;
	}

	.pup-nav-h2 {
		font-size: 2rem;
	}
	
	.btn-curr, .btn-prev {
		cursor: pointer;
	}
	
	.fa-layers-text {
		color: white;
		margin-left: 2px;
	}

	/* PuppyCards */
	.row {
		margin: auto;
	}

	.col {
		padding: 15px;
	}

	.card-img-top {
		max-width: 100%;
		object-fit: cover;
		padding: 10px;
	}

	.card-text {
		font-size: 1.5rem;
		font-weight: 500;
	}

	.card {
		box-shadow: 0 0 5px 1px rgb(145, 145, 145);
	}

	.sire-and-dam-p {
		margin: auto;
		font-size: 1.5rem;
		text-align: left;
		padding: 0 4.5rem;
	}

	.middle-col {
		margin: auto;
	}

	/* Sire and Dam */
	.puppies-header {
		font-size: 1.75rem;
		margin: 0;
		padding: 5% 3%;
	}
}

@media screen and (min-width: 768px) {
	/* PuppiesNavigation */
	.puppies-nav-container {
		width: 80%;
		margin: auto;
		padding: 10px 0 20px;
	}
	
	.btn-curr, .btn-prev {
		cursor: pointer;
		transform: scale(1.25, 1.25);
	}

	.pup-nav-h2 {
		font-size: 3rem;
	}
	
	.fa-layers-text {
		color: white;
		margin-left: 2px;
	}

	/* PuppyCards */
	.row {
		margin: auto;
	}

	.col {
		padding: 15px;
	}

	.card-img-top {
		max-width: 100%;
		object-fit: cover;
		padding: 10px;
	}

	.card-text {
		font-size: 1.75rem;
		font-weight: 500;
	}

	.card {
		box-shadow: 0 0 5px 1px rgb(145, 145, 145);
	}

	.sire-and-dam-p {
		margin: auto;
		font-size: 1.5rem;
		text-align: left;
		padding: 0 4.5rem;
	}

	.middle-col {
		margin: auto;
	}

	/* Sire and Dam */
	.puppies-header {
		font-size: 2.5rem;
		margin: 0;
		padding: 5% 3%;
	}

}

@media screen and (min-width: 1200px) {
	/* PuppiesNavigation */
	.puppies-nav-container {
		width: 60%;
		margin: auto;
		padding: 10px 0 20px;
	}

	.pup-nav-h2 {
		font-size: 4rem;
	}

	.btn-curr {
		border-radius: 25px;
		width: 500px;
		background-color: pink;
	}

	/* PuppyCards */
	.row {
		margin: auto;
	}

	.col {
		padding: 15px;
	}

	.card-img-top {
		max-width: 100%;
		object-fit: cover;
		padding: 10px;
	}

	.card-text {
		font-size: 1.75rem;
		font-weight: 500;
	}

	.card {
		box-shadow: 0 0 5px 1px rgb(145, 145, 145);
	}

	.sire-and-dam-p {
		margin: auto;
		font-size: 1.5rem;
		text-align: left;
		padding: 0 4.5rem;
	}

	.middle-col {
		margin: auto;
	}

	/* Sire and Dam */
	.puppies-header {
		font-size: 3rem;
		margin: 0;
		padding: 5% 3%;
	}
}

.fa-paw {
	transform: scale(2.5, 2.5);
	color: rgb(0, 0, 0);
}

.bg-paw {
	transform: scale(2.8, 2.8);
	color: rgb(64, 179, 207);
}

.bg-paw2 {
	transform: scale(2.8, 2.8);
	color: rgb(215, 216, 255);
}

.btn {
	border: 0;
	padding: 0;
	width: 115px;
	height: 45px;
	border-radius: 15px;
	box-shadow: 5px 5px 10px 1px;
}

.btn:hover {
	transform: scale(1.3, 1.3);
}

.btn-curr {
	background-color: rgb(221, 250, 116);
}

.btn-prev {
	background-color: rgb(255, 174, 0);
}

.btn:focus {
	box-shadow: 5px 5px 10px 1px;
}