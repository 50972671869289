@media screen and (max-width: 767px) {
  .contact-container {
    display: flex;
    height: 88vh;
    justify-content: center;
    align-items: center;
  }

  .contact-row {
    height: 20vh;
    width: 100%;
  }
  
  .contact-container p {
    display: block;
    padding-top: 25px;
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  .contact-container {
    display: flex;
    height: 86vh;
    justify-content: center;
    align-items: center;
  }

  .contact-row {
    height: 20vh;
    width: 100%;
  }
  
  .contact-container p {
    display: block;
    padding-top: 25px;
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .contact-container {
    display: flex;
    height: 86vh;
    justify-content: center;
    align-items: center;
  }

  .contact-row {
    height: 20vh;
    width: 100%;
  }
  
  .contact-container p {
    display: inline-block;
    padding-left: 100px;
    font-size: 2.5rem;
  }
}
